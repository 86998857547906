<div class="modal-footer justify-content-end mt-4 px-4 position-absolute w-100 bottom-0 bg-white">
  <button
    type="button"
    class="btn btn-default bg-white px-4"
    [disabled]="cancelDisabled"
    (click)="onCancelClick.emit()"
  >
    {{ cancelLabel }}
  </button>
  <button type="submit" class="btn bg-blue border-0 px-4 mr-0" [disabled]="saveDisabled">
    {{ saveLabel }}
  </button>
</div>

import { Directive, ElementRef, HostListener, Optional, Renderer2 } from "@angular/core";
import { NgControl } from "@angular/forms";
 
@Directive({
  selector: "[appEmojiValidaton]",
})
export class EmojiValidatonDirective {

 
  constructor(private el: ElementRef, @Optional() private ngControl: NgControl) {}

  @HostListener('input', ['$event']) onInput(event: Event): void {
    const inputValue = this.el.nativeElement.value;
    const sanitizedValue = this.removeInvalidCharacters(inputValue);
    this.updateValueAndValidation(sanitizedValue);
  }

  @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent): void {
    event.preventDefault(); // Prevent the default paste action
    const clipboardData = event.clipboardData;
    if (clipboardData) {
      const pastedText = clipboardData.getData('text/plain');
      const sanitizedText = this.removeInvalidCharacters(pastedText);
      document.execCommand('insertText', false, sanitizedText);
      this.updateValueAndValidation(this.el.nativeElement.value);
    }
  }

  private removeInvalidCharacters(value: string): string {
    if(value)  // Regex pattern to allow alphanumeric characters, spaces, and some special characters
    return value.replace(/[^\w\s!"#$%&'()*+,\-.\/:;<=>?@[\\\]^_`{|}~]+/g, '');
  }

  private updateValueAndValidation(value: string): void {
    if (this.ngControl) {
      this.ngControl.control.setValue(value);
      this.ngControl.control.markAsDirty();
      this.ngControl.control.markAsTouched();
    }
  }
}
import { NgModule } from "@angular/core";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { AbpHttpInterceptor } from "abp-ng2-module";

import * as ApiServiceProxies from "./service-proxies";

@NgModule({
  providers: [
    ApiServiceProxies.RoleServiceProxy,
    ApiServiceProxies.SessionServiceProxy,
    ApiServiceProxies.TenantServiceProxy,
    ApiServiceProxies.UserServiceProxy,
    ApiServiceProxies.TokenAuthServiceProxy,
    ApiServiceProxies.AccountServiceProxy,
    ApiServiceProxies.ConfigurationServiceProxy,
    ApiServiceProxies.SourceServiceServiceProxy,
    ApiServiceProxies.CredentialServicesServiceProxy,
    ApiServiceProxies.FilterServicesServiceProxy,
    ApiServiceProxies.SelectedFilterServiceServiceProxy,
    ApiServiceProxies.UserFilterServiceServiceProxy,
    ApiServiceProxies.QuestionServiceServiceProxy,
    ApiServiceProxies.SelectedUserAnswerServicesServiceProxy,
    ApiServiceProxies.AppliedJobServicesServiceProxy,
    ApiServiceProxies.RejectedJobServicesServiceProxy,
    ApiServiceProxies.CommonServiceServiceProxy,
    ApiServiceProxies.SubscriptionServiceServiceProxy,
    ApiServiceProxies.SkillServicesServiceProxy,
    ApiServiceProxies.UserQuestionServicesServiceProxy,
    ApiServiceProxies.UserSkillServiceServiceProxy,
    ApiServiceProxies.DashboardServiceServiceProxy,
    ApiServiceProxies.RejectedJobServicesServiceProxy,
    ApiServiceProxies.CaptchaServiceServiceProxy,
    ApiServiceProxies.FilterLogServiceServiceProxy,
    ApiServiceProxies.VerificationServiceServiceProxy,
    ApiServiceProxies.FaqServiceServiceProxy,
    ApiServiceProxies.PaymentLogsServiceServiceProxy,
    ApiServiceProxies.SupportServiceProxy,
    ApiServiceProxies.UserAddressServiceServiceProxy,
    ApiServiceProxies.JobPackageServicesServiceProxy,
    ApiServiceProxies.PromoCodeServiceServiceProxy,
    ApiServiceProxies.ActivityLogsServiceServiceProxy,
    ApiServiceProxies.GptLogServicesServiceProxy,
    ApiServiceProxies.FeedbackQuestionsServiceServiceProxy,
    ApiServiceProxies.FeedBackQuestionsAnsServicesServiceProxy,
    ApiServiceProxies.UniversityManagmentServicesServiceProxy,
    ApiServiceProxies.SuperAdminDashbardServiceServiceProxy,
    ApiServiceProxies.UniversityAdminDashboardServiceServiceProxy,
    ApiServiceProxies.NotificationServiceServiceProxy,

    { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true },
  ],
})
export class ServiceProxyModule { }

import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appNoSpaceInput]'
})
export class NoSpaceInputDirective {

  constructor() { }
 
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    // Prevent input of space character
    if (event.key === ' ' || event.code === 'Space') {
      event.preventDefault();
    }
  }
  @HostListener('input', ['$event'])
  onInput(event: InputEvent) {
    const inputElement = event.target as HTMLInputElement;
    const strippedValue = inputElement.value.replace(/\s/g, ''); // Remove all whitespace characters
    if (strippedValue !== inputElement.value) {
      inputElement.value = strippedValue;
      inputElement.dispatchEvent(new Event('input')); // Trigger input event to update model value
    }
  }
}
